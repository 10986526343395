var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu"},[[_c('div',{staticClass:"subpageHeader"},[_c('div',{attrs:{"id":"nav"}},[_c('b-navbar',{attrs:{"type":"light","toggleable":"lg","id":"topNav"}},[_c('b-navbar-brand',{attrs:{"to":"/"}},[_c('img',{staticClass:"logoImg",class:{
              margB:
                this.$route.name !== 'home',
            },attrs:{"src":"https://wp-ocssport.ocs-sport.com/wp-content/uploads/2025/01/pgm-logo.webp"}})]),_c('b-navbar-toggle',{directives:[{name:"b-modal",rawName:"v-b-modal.nav-collapse",modifiers:{"nav-collapse":true}}],staticClass:"ml-auto",attrs:{"type":"light"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/"}},[_vm._v(" HOME ")]),_c('b-nav-item',{attrs:{"to":"/schedule"}},[_vm._v(" SCHEDULE ")]),(this.okfound == 'Y' && this.course !== null)?[_c('b-nav-item',{attrs:{"to":{
                  name: 'reports',
                  query: {
                    url: _vm.currentReport(),
                    id: _vm.season,
                    code: _vm.course,
                    fTour: true,
                    title: _vm.currentTitle(),
                  },
                }}},[_vm._v(" RESULTS ")])]:_vm._e(),_c('b-nav-item',{attrs:{"to":"/oom"}},[_vm._v(" OOM ")]),_c('b-nav-item',{staticClass:"rightPix",attrs:{"to":"/news"}},[_vm._v(" NEWS ")])],2)],1),_c('b-modal',{attrs:{"hide-header":"","hide-footer":"","id":"nav-collapse"}},[_c('b-button',{staticClass:"closeBtn",on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_c('img',{staticClass:"closeIcon",attrs:{"src":"https://assets.asiantour.com/toyota-tour/2024/02/close_360.png"}})]),_c('div',{staticClass:"mobileMenu"},[_c('div',{staticClass:"menuGroup"},[_c('b-nav-item',{attrs:{"to":"/"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" HOME ")]),_c('b-nav-item',{attrs:{"to":"/schedule"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" SCHEDULE ")]),(this.okfound == 'Y' && this.course !== null)?[_c('b-nav-item',{attrs:{"to":{
                    name: 'reports',
                    query: {
                      url: _vm.currentReport(),
                      id: _vm.season,
                      code: _vm.course,
                      fTour: true,
                      title: _vm.currentTitle(),
                    },
                  }},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" RESULTS ")])]:_vm._e(),_c('b-nav-item',{attrs:{"to":"/oom"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" OOM ")]),_c('b-nav-item',{attrs:{"to":"/news"},on:{"click":function($event){return _vm.$bvModal.hide('nav-collapse')}}},[_vm._v(" NEWS ")])],2)])],1)],1)],1),_c('div',{staticClass:"redbanner"})]),_c('div',{staticClass:"videoTextBlock"},[(_vm.$route.name == 'home')?[(_vm.isMobileimage(true))?[_c('div',{staticClass:"mobileImage"})]:[_c('div',[_c('b-carousel',{staticStyle:{"text-shadow":"0px 0px 2px #000"},attrs:{"id":"carousel-fade","fade":"","indicators":"","img-width":"1024","img-height":"480"}},[_c('b-carousel-slide',{staticClass:"sliderImage",attrs:{"img-src":"https://wp-ocssport.ocs-sport.com/wp-content/uploads/2025/01/web-2.logo_.jpg"}})],1)],1)],(this.$route.name === 'home')?[_c('div',{staticClass:"bannerTitle container"},[_c('h1',{staticClass:"BannerText"},[_vm._v(" "+_vm._s(_vm.homeSectionData.first_line_text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.homeSectionData.second_line_text)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.homeSectionData.slogan)+" "),_c('br')])])]:_vm._e()]:_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }